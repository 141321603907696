import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import videojs from "video.js";

// eslint-disable-next-line
import Record from "videojs-record/dist/videojs.record.js";
import { mapMutations } from "vuex";

import CardTitle from "@/components/CardTitle.vue";
import SideCountdown3s  from "@/components/SideCountdown3s.vue";
import SideCountdown from "@/components/SideCountdown.vue";
import { saveLink } from "@/BackendClient.js";
import oLight from '@/objects/o-light/o-light.vue';

export default {
  components: {
    SideCountdown3s,
    SideCountdown,
    'o-light': oLight,
    CardTitle
  },
  data() {
    return {
      showTimerVideo: true, 
      initialDelaySeconds: 11,
      show_countdown: false,
      video_recording: false, 
      player: "",
      options: {
        controls: true,
        autoplay: false,
        fluid: false,
        loop: false,
        width: screen.availWidth,
        height: screen.availHeight,
        controlBar: {
          volumePanel: false
        },
        plugins: {
          // configure videojs-record plugin
          record: {
            audio: true,
            debug: true,
            video: {
              // Define the camera stream min, exact or maximum size. Refer to
              // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Parameters
              // for more details
              width: { min: 1280 },
              height: { min: 720 }
            },
            maxLength: 30,
            // Dimensions of captured video frames
            frameWidth: 1280,
            frameHeight: 720
          }
        }
      },
      readyToRecord: false
    }
  },
  watch: {
    video_recording: {
      handler(video_recording){
        if(video_recording) this.player.record().start()
      }
    }
  },
  methods: {
    fileUploadAndGetURL(file) {
      let url = process.env.VUE_APP_VIDEO_UPLOAD_URL;
      let xhr = new XMLHttpRequest();
      let fd = new FormData();

      xhr.open("POST", url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      let self = this;

      xhr.onreadystatechange = function(e) {
        console.log('upload ready');
        console.log(e);
        if (xhr.readyState == 4 && xhr.status == 200) {
          // File uploaded successfully
          let response = JSON.parse(xhr.responseText);
          // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
          let url = response.secure_url;
          
          self.$store.commit('videoIsUploaded', true);

          console.log('Video => uploaded: ' + self.$store.state.video.uploaded + " ', url: ' + " + url);

          saveLink(url).then(function (key){
            let video_link = self.$router.resolve({
              name: 'memories_page', 
              query: {video_id: key}
              }).href
            let absolute_video_link = 'https://vlog.explorezone.nl' + video_link
            self.UPDATE_UPLOADED_VIDEO_URL(absolute_video_link);
          })
        } else {
          throw Error(`failed to upload to cloudinary ${e}`)
        }
      };
      const unsignedUploadPreset = process.env.VUE_APP_CLOUDINARY_PRESET;

      fd.append("upload_preset", unsignedUploadPreset);
      fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
      fd.append("file", file);
      xhr.send(fd);
    },
    async goToWatchVideo(blobURL) {
      setTimeout(() => {
        this.$router.push({
          name: "watch-video",
          params: { video_src: blobURL }
        });
      }, 200);
    },
    ...mapMutations(["UPDATE_UPLOADED_VIDEO_URL"])
  },
  mounted() {
    /* eslint-disable no-console */
    this.player = videojs("#myVideo", this.options, () => {
      // print version information at startup
      var msg =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version;
      videojs.log(msg);
    });

    // device is ready
    this.player.on("deviceReady", () => {
      this.readyToRecord = true;
      this.UPDATE_UPLOADED_VIDEO_URL(null);
      this.$refs.timer_video.play()
      setTimeout(() => {
        this.show_countdown = true
      }, 8000);
    });

    // user clicked the record button and started recording
    this.player.on("startRecord", () => {
      console.log("started recording!");
    });

    // user completed recording and stream is available
    this.player.on("finishRecord", () => {
      // the blob object contains the recorded data that
      // can be downloaded by the user, stored on server etc.
      console.log("finished recording: ", this.player.recordedData);

      var blobURL = window.URL.createObjectURL(this.player.recordedData);

      // the blob object contains the recorded data that
      this.fileUploadAndGetURL(this.player.recordedData);
      this.goToWatchVideo(blobURL);
    });

    // error handling
    this.player.on("error", (element, error) => {
      console.warn(error);
    });

    this.player.on("deviceError", () => {
      console.error("device error:", this.player.deviceErrorCode);
    });

    this.player.record().getDevice();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}