<template>
    <div class="wrapper">
        <div class="circle center">
            <p class="countdown-text">{{ seconds }}</p>
            <div class="l-half"></div>
            <div class="r-half"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: "SideCountdown",
    data: function () {
        return {
            seconds: 3
        }
    },
    mounted: function () {
        var self = this;

        // Decrease the countdown
        for (var i = 0; i < this.seconds; i++) {
            window.setTimeout(function () {
                self.seconds -= 1;
            }, 1000 * (i + 1));
        }

        // Emit a 'countdown ended' event
        window.setTimeout(function () {
            self.$emit('countdownEnded');
        }, 1000 * this.seconds);
    }
}

</script>

<style scoped>

.countdown-text {
    font-family: 'Colfax-Bold';
    font-size: 64px;
}

</style>

<style lang="scss" scoped>

html, body { height: 100%; }
body { background: #f00; }

$d: 150;
$w: 75;
$t: 3;

.countdown-text {
    z-index: 2;
    position: absolute;
    bottom: 25px;
    width: 100%;
    transform: scaleX(-1);
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -($d / 2)+px;
    margin-left: -($d / 2)+px;
}

@import "../assets/scss/countdown.scss"

</style>